import React, { useState, useEffect } from "react";
import data from "./userData.json";
import { Grid, Typography } from "@material-ui/core";

import MidPlan from "../LandingV2/PlansRender/TemplatePlans";
import UserAddress from "./UserAddress";
import UserInfo from "../LandingV2/UserInfo/UserInfo";
export default function UserShow() {
  const [user, setuser] = useState(data);
  const [datMap, setdatMap] = useState([]);
  useEffect(() => {
    creatingLabels();
  }, []);
  const creatingLabels = p => {
    let planDa = {};
    let simDa = [];
    let BoxsDa = [];
    planDa = [
      { label: user.mainPlanData.label, id: "m01" },
      { label: user.mainPlanData.simCard.length, id: "m02" },
      { label: user.mainPlanData.tv, id: "m03" },
      { label: user.mainPlanData.status, id: "m04" }
    ];
    user.mainPlanData.simCard.forEach(element => {
      if (element.number) {
        simDa.push({
          sim: element.sim,
          number: element.number,
          mobileD: element.mobileD,
          mobileSer: element.mobileSer
        });
      }
    });
    if (user.mainPlan.boxes) {
      user.mainPlan.boxe1.forEach(element => {
        BoxsDa.push({
          imei: element.imei,
          simcard: element.sim,
          plan: element.plan,
          status: element.status
        });
      });
    }
    setdatMap([
      {
        label: "Main Plan",
        value: planDa,
        id: "plansss01",
        type: "main"
      },
      {
        label: "Sim Cards",
        value: simDa,
        id: "plansss02",
        type: "sim"
      },
      {
        label: "Boxes",
        value: BoxsDa,
        id: "plansss03",
        type: "box"
      }
    ]);
  };

  const mappinPlans = p => (
    <div key={p.id} style={{ marginTop: "2em", textAlign: "center" }}>
      <Typography variant="h5">{p.label}</Typography>
      <MidPlan data={p.value} type={p.type} />
    </div>
  );
  return (
    <Grid container style={{ padding: "1em", marginTop: "1em" }} spacing={6}>
      <Grid item xs={12} sm={4}>
        <UserAddress user={user} />
      </Grid>
      <Grid item xs={12} sm={8}>
        {datMap.map(mappinPlans)}
      </Grid>
    </Grid>
  );
}
