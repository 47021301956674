import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import { Grid, Typography, Checkbox } from "@material-ui/core";
//custome lib
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
// custume components
import { TextFieldSty } from "../Container/components";
import Logins from "../Container/Logins";
import Loader from "../Container/Loader";

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  load: false,
  checkedA: false,
  error: null
};
class SignUpFormBase extends Component {
  state = {
    ...INITIAL_STATE
  };
  componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.setState({ load: false });
        this.props.history.push(ROUTES.LANDING);
      } else {
        this.setState({ load: true });
      }
    });
  }
  onSubmit = event => {
    const { username, email, passwordOne, plan } = this.state;
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.userFire(authUser.user.uid).set({
          username,
          email,
          role: "client"
        });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.STEPS);
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  radioChange = event => {
    this.setState({ plan: event.target.value });
  };
  checkedChange = event => {
    this.setState({ checkedA: event.target.checked });
  };
  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
      checkedA
    } = this.state;
    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "" ||
      checkedA === false;
    return !this.state.load ? (
      <Loader />
    ) : (
      <Logins title="SignUp">
        <Typography variant="h4" className="titleLogins">
          Get your free account Today
        </Typography>
        <Typography variant="h6" id="subtitle">
          The Best Mobile Quadruple Play Service!
        </Typography>
        <form onSubmit={this.onSubmit}>
          <TextFieldSty
            id="standard-name"
            label="Name"
            name="username"
            className="textField2"
            value={username}
            onChange={this.onChange}
            margin="normal"
          />
          <TextFieldSty
            id="standard-email"
            label="Email"
            name="email"
            className="textField2"
            value={email}
            onChange={this.onChange}
            margin="normal"
          />
          <TextFieldSty
            id="standard-passwordOne"
            label="Password"
            name="passwordOne"
            className="textField2"
            value={passwordOne}
            onChange={this.onChange}
            margin="normal"
            type="password"
          />
          <TextFieldSty
            id="standard-passwordTwo"
            label="Confirm Password"
            name="passwordTwo"
            className="textField2"
            value={passwordTwo}
            onChange={this.onChange}
            margin="normal"
            type="password"
          />
          <div className="loginOptions" style={{ marginBottom: "0.5em" }}>
            <Checkbox
              checked={checkedA}
              onChange={this.checkedChange}
              value="checkedA"
              color="primary"
              inputProps={{
                "aria-label": "primary checkbox"
              }}
            />
            I agree to the terms and receiving notifications from mobilepcs*tv
          </div>
          <Button
            disabled={isInvalid}
            onClick={this.onSubmit}
            variant="contained"
            color="primary"
            style={{ width: "100%" }}
          >
            Next
          </Button>
          {error && <p>{error.message}</p>}
        </form>
      </Logins>
    );
  }
}
const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);
const SignUpForm = compose(
  withRouter,
  withFirebase
)(SignUpFormBase);
export default SignUpForm;
export { SignUpForm, SignUpLink };
