import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Typography, Button } from "@material-ui/core";

//local im
import { SignUpLink } from "../SignUp";
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

// custume components
import { TextFieldSty } from "../Container/components";
import Logins from "../Container/Logins";
import Loader from "../Container/Loader";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  load: false
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.props.history.push(ROUTES.LANDING);
        this.setState({ load: false });
      } else {
        this.setState({ load: true });
      }
    });
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === "" || email === "";

    return !this.state.load ? (
      <Loader />
    ) : (
      <Logins title="SignIn">
        <Typography variant="h4" className="titleLogins">
          Welcome Back
        </Typography>
        <TextFieldSty
          id="standard-email"
          label="Email"
          name="email"
          className="textField"
          value={email}
          onChange={this.onChange}
          margin="normal"
        />
        <TextFieldSty
          id="standard-password"
          label="Password"
          name="password"
          className="textField"
          value={password}
          type="password"
          onChange={this.onChange}
          margin="normal"
        />
        <Button
          variant="contained"
          disabled={isInvalid}
          color="primary"
          className="btn"
          onClick={this.onSubmit}
        >
          Sign In
        </Button>
        {error && <p>{error.message}</p>}
        <div className="loginOptions">
          <PasswordForgetLink />
          <SignUpLink />
        </div>
      </Logins>
    );
  }
}
const SignInForm = compose(
  withRouter,
  withFirebase
)(SignInFormBase);
export default SignInForm;
export { SignInForm };
