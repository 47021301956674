import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import * as firebase from "firebase";
//firebase
import { withFirebase } from "../../Firebase";
// custume components
import Step2 from "./Step2";
import Loader from "../../Container/Loader";
import Step3 from "./Step3";

class StepsBase extends Component {
  state = {
    step: true,
    planselec: "",
    planLabel: "",
    billingInformation: [],
    simCard: [],
    stripeId: "",
    sizeSimArr: 0,
    sizeSim: 0,
    userData: {
      name: ""
    },
    showCheck: true,
    vendorId: "",
    vendorDb: "",
    loader: true
  };

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.dataUser(authUser.uid)
        : this.props.history.push(ROUTES.SIGN_UP);
    });
  }
  // collect email
  dataUser(data) {
    this.props.firebase
      .userFire(data)
      .get()
      .then(doc => {
        if (doc.exists) this.setState({ userData: doc.data(), loader: false });
      })
      .then(() => {
        if (this.state.userData.stripeId)
          this.props.history.push(ROUTES.LANDING);
        if (this.state.userData.role === "vendor")
          this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => console.log(error));
  }
  // final push
  onSubmit = () => {
    this.props.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) this.pushData(authUser.uid);
    });
  };

  findVendor = () => {
    this.props.firebase
      .usersFire()
      .get()
      .then(doc => {
        doc.forEach(element => {
          let docs = { id: element.id, data: element.data() };
          if (docs.data.vendorId === this.state.vendorId) {
            this.setState({ vendorDb: docs.id });
          }
        });
      });
  };

  pushData(ui) {
    const {
      billingInformation,
      simCard,
      stripeId,
      planselec,
      planLabel,
      vendorId,
      userData,
      vendorDb
    } = this.state;

    const mainPlan = {
      label: planLabel,
      simCard: simCard,
      tv: "Processing",
      status: "Processing"
    };

    if (this.state.stripeId) {
      this.props.firebase
        .userFire(ui)
        .update({
          billingAddress: billingInformation[0].address,
          stripeId: stripeId,
          mainPlan: planselec,
          mainPlanData: mainPlan,
          referral: vendorId
        })
        .then(() => {
          if (vendorDb !== "") {
            this.props.firebase.userFire(vendorDb).update({
              clients: firebase.firestore.FieldValue.arrayUnion({
                name: userData.username,
                typePlan: planLabel,
                status: true
              })
            });
          }
          this.props.history.push(ROUTES.LANDING);
        });
    }
  }
  /// stripe id
  changeStripeId = data => {
    this.setState({ stripeId: data });
    if (this.state.stripeId !== "") this.onSubmit();
    this.changeShow();
  };

  //changes states
  changeStep = (plan, step, size, planLabel) => {
    if (step)
      this.setState({
        step: false,
        planselec: plan,
        sizeSimArr: size,
        planLabel: planLabel
      });
  };

  changeVendor = data => {
    this.setState({ vendorId: data });
    this.findVendor();
  };

  changeSim = data => {
    this.setState({ simCard: data });
    this.checkVal();
  };

  changeBilling = data => {
    this.setState({ billingInformation: data });
  };

  changeShow = () => {
    this.setState({ showCheck: false });
  };

  checkVal = () => {
    let { simCard } = this.state;
    let save = [];
    if (simCard.length > 0) {
      simCard.forEach(element => {
        if (element.value !== "") {
          if (save.length < simCard.length) {
            if (!save[element.id]) {
              save.push(element.name);
            }
          }
        }
      });
      save.length === simCard.length
        ? this.setState({ sizeSim: simCard.length })
        : this.setState({ sizeSim: 0 });
    }
  };
  render() {
    const {
      step,
      planselec,
      billingInformation,
      sizeSim,
      sizeSimArr,
      loader
    } = this.state;
    const invalid =
      sizeSim < sizeSimArr || billingInformation.length < 0 || planselec === "";
    return loader ? (
      <Loader />
    ) : (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{
          marginTop: "2em",
          padding: "2em",
          paddingRight: 0
        }}
      >
        {step ? (
          <Step2 changeStep={this.changeStep} />
        ) : (
          <Step3
            changeStep={this.changeStep}
            plan={planselec}
            billingInformation={this.state.billingInformation}
            changeBilling={this.changeBilling}
            stripe={this.changeStripeId}
            show={this.state.showCheck}
            userData={this.state.userData}
            invalid={invalid}
            changeVendor={this.changeVendor}
            sim={this.changeSim}
          />
        )}
      </Grid>
    );
  }
}

const Steps = compose(
  withRouter,
  withFirebase
)(StepsBase);

export default Steps;
