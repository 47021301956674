import React, { useState } from "react";
import { TextField, Typography, Button } from "@material-ui/core";
import data from "./vendorDa.json";
import Logins from "../Container/Logins";

export default function Regis(props) {
  const [fields, setfields] = useState(data.registration);
  const onChange = event => {
    let temFields = [];
    fields.forEach(element => {
      let ele = element;
      if (element.name === event.target.name) {
        ele.value = event.target.value;
      }
      temFields.push(ele);
    });
    setfields(temFields);
    props.check(temFields);
  };

  const mappinFields = p => (
    <TextField
      id={p.id}
      key={p.id}
      label={p.label}
      name={p.name}
      value={p.value}
      onChange={onChange}
      type={p.type}
      style={{ width: "95%" }}
      margin="normal"
    />
  );
  return (
    <Logins title="Vendor">
      <Typography variant="h5" style={{ textAlign: "center" }}>
        Vendor SignUp
      </Typography>
      {fields.map(mappinFields)}
      <Button
        variant="contained"
        color="primary"
        disabled={props.Invalid}
        style={{ width: "100%" }}
        onClick={() => props.submit(fields)}
      >
        Send
      </Button>
    </Logins>
  );
}
