import React, { Component } from "react";
import { Typography, Grid } from "@material-ui/core";

import { GridSty } from "./components";

export default class Logins extends Component {
  state = {
    logo:
      "https://firebasestorage.googleapis.com/v0/b/mobilepcstv-cf2c1.appspot.com/o/ori.png?alt=media&token=1732890b-ad34-4856-93a1-eb6635f2593c"
  };
  render() {
    const { logo } = this.state;
    return (
      <div className="gridContainer">
        <GridSty
          container
          justify="center"
          alignItems="center"
          className="gridCont"
        >
          <Grid item xs={12} sm={12} className="gridLogins">
            <img src={logo} alt="Logo mobilepcs*tv" width="30%" />
            {this.props.children}
          </Grid>
        </GridSty>
      </div>
    );
  }
}
