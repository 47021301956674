import React, { Component } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import Loader from "../Container/Loader";
import Regis from "./Regis";

import { withFirebase } from "../Firebase";
class VendorBase extends Component {
  state = {
    username: "",
    email: "",
    passwordOne: "",
    cpassword: "",
    load: false,
    error: null
  };
  componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.setState({ load: false });
        this.props.history.push(ROUTES.LANDING);
      } else {
        this.setState({ load: true });
      }
    });
  }
  search = data => {
    data.forEach(element => {
      if (element.name === "vendor-name") {
        this.setState({ username: element.value });
      }
      if (element.name === "vendor-email") {
        this.setState({ email: element.value });
      }
      if (element.name === "vendor-password") {
        this.setState({ passwordOne: element.value });
      }
      if (element.name === "vendor-cpasssword") {
        this.setState({ cpassword: element.value });
      }
    });
  };
  makeid = length => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  onSubmit = data => {
    let { username, email, passwordOne } = this.state;
    let randomS = this.makeid(3);
    let vendorId = `ven${username.charAt(0)}do${randomS}r${username.charAt(
      username.length - 1
    )}${email.charAt(2)}${passwordOne.charAt(1)}`;
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.userFire(authUser.user.uid).set({
          username,
          email,
          role: "vendor",
          vendorId: vendorId,
          clients: []
        });
      })
      .then(() => {
        this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => {
        this.setState({ error });
      });
  };
  render() {
    const { username, email, passwordOne, cpassword, error, load } = this.state;
    const Invalid =
      passwordOne !== cpassword ||
      passwordOne === "" ||
      passwordOne.length < 7 ||
      email === "" ||
      username === "";
    return !load ? (
      <Loader />
    ) : (
      <div>
        {" "}
        <Regis submit={this.onSubmit} Invalid={Invalid} check={this.search} />
        {error && <p>{error.message}</p>}
      </div>
    );
  }
}

const Vendor = compose(
  withRouter,
  withFirebase
)(VendorBase);

export default Vendor;
