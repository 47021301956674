import React, { useState, useEffect } from "react";
import { Avatar, Typography } from "@material-ui/core";

// custume component
import Cards from "../../Container/Card";

export default function UserInfo(props) {
  const [user, setuser] = useState(props.userData);
  const [label, setlabel] = useState(props.label);
  const [actionS, setactionS] = useState(false);
  const [labelRende, setlabelRende] = useState();
  const { username, mainPlanData, vendorId, email } = user;

  useEffect(() => {
    if (user.role === "client") {
      setactionS(true);
      setlabelRende(user.mainPlanData.label);
    }
    if (label === "Show") setactionS(true);
    if (user.role === "vendor") setlabelRende("Vendor");
  }, []);
  let avatar = username.toCa;
  const onMessages = () => {
    console.log("mensajes");
  };
  const VendorDiv = () => (
    <div>
      {user.role.toUpperCase()}
      <div
        style={{
          width: "100%",
          background: "linear-gradient(86deg, #ffc107 0%, #ffa000 100%)",
          padding: "0.6em",
          marginTop: "0.5em",
          fontWeight: 400
        }}
      >
        Id => {vendorId}
      </div>
    </div>
  );
  return (
    <Cards
      actions={actionS}
      buttonText={label}
      cardStyle={{ padding: 0, paddingTop: "1em" }}
      style={{ textAlign: "center" }}
      eventButton={props.eventButton}
      buttonStyle={{
        width: "100%",
        padding: "0.6em",
        paddingBottom: "1 em",
        borderRadius: 0,
        marginTop: 0
      }}
    >
      <Avatar
        style={{
          margin: "0 auto",
          height: 80,
          width: 80,
          fontSize: 40,
          backgroundColor: "#003057"
        }}
      >
        {username.charAt(0).toUpperCase()}
      </Avatar>
      <Typography variant="h6" style={{ marginTop: "1em", fontSize: "0.9em" }}>
        {email}
        <br />
        {actionS ? (
          <div style={{ margin: "0.5em" }}> {labelRende}</div>
        ) : (
          <VendorDiv />
        )}
      </Typography>
    </Cards>
  );
}
