import React, { Component } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../Firebase";
import Loader from "../Container/Loader";
import AppBar from "../AppBar";
import RenderInfo from "./RenderInfo";
import UserShow from "./UserShow";
class AdminPageBase extends Component {
  state = {
    users: [],
    load: false,
    userInnfo: false
  };
  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.checkDataBase(authUser.uid)
        : this.props.history.push(ROUTES.SIGN_IN);
    });
  }
  eventButton = uid => {
    this.setState({ userInnfo: true });
  };
  checkDataBase = uid => {
    let temArray = [];
    this.setState({ id: uid });
    this.props.firebase
      .usersFire()
      .get()
      .then(doc => {
        doc.forEach(element => {
          temArray.push({
            id: element.id,
            data: element.data()
          });
          this.setState({ users: temArray, load: true });
        });
      })
      .catch(error => console.log(error));
  };
  render() {
    const { load, userInnfo } = this.state;
    const RenderUsers = p => (
      <Grid container spacing={6} style={{ marginTop: "1em", padding: "1em" }}>
        {this.state.users.map(p => (
          <RenderInfo
            eventButton={() => this.eventButton(p.id)}
            user={p}
            key={p.email}
          />
        ))}
      </Grid>
    );
    return (
      <div>
        <AppBar />
        {load ? userInnfo ? <UserShow /> : <RenderUsers /> : <Loader />}
      </div>
    );
  }
}

const AdminPage = compose(
  withFirebase,
  withRouter
)(AdminPageBase);

export default AdminPage;
