import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";

export const FormContainer = styled.div`
  background: #fafafa;
  margin: 0 auto;
  padding: 1em;
  text-align: center;
`;
export const ContainerGeneral = styled.div`
  width: 100%;
  text-align: center;
`;
export const TextFieldSty = styled(TextField)`
  display: block;
  width: 45%;
  margin: 1em;
`;
export const GridSty = styled(Grid)`
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px;
  margin: 0 auto;
  margin-top: 10vh;
`;
