import React from "react";

export default function GenerateBill(props) {
  return (
    <div id="generateBill">
      <div id="topBill">
        <div id="rigthBill">
          <div id="logobill">
            <img src={props.logo} alt="logo" width="100%" />
          </div>
          <div id="addressBill">
            Mobilepcs*tv
            <br />
            Street
            <br />
            ZipCode
            <br />
            TaxId
          </div>
        </div>
        <div id="leftBill">
          <div id="invoiceInfo">
            Invoice <span id="neg">#{props.data.invoice}</span>
            <br />
            {props.data.date}
          </div>
        </div>
      </div>
      <div id="midBillInfo">
        <h3 id="h3bill">Invoice For: {props.data.name}</h3>
        <table id="tableBill">
          <tr>
            <th>Description</th>
            <th>Amount</th>
          </tr>
          <tr>
            <td>{props.data.descrip}</td>
            <td>{props.data.amount}</td>
          </tr>
        </table>
      </div>
      <div id="footerBill">
        <div id="paymentMethod">
          <h3 id="h3bill">Method of Payment: Credit Card</h3>
        </div>
        <div id="contactBill">
          If you need have with your invoice write us to <br />
          <strong>customer@mobilepcs.tv</strong>
        </div>
      </div>
    </div>
  );
}
