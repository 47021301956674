import React, { Component } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
//firebase
import { withFirebase } from "../Firebase";
//routes
import * as ROUTES from "../../constants/routes";

import Loader from "../Container/Loader";
import Drawer from "../Container/landingContainer/Drawer";

class LandingBase extends Component {
  state = {
    loading: true,
    user: {},
    planLabel: ""
  };

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.checkDataBase(authUser.uid)
        : this.props.history.push(ROUTES.SIGN_IN);
    });
  }

  checkDataBase = uid => {
    this.setState({ id: uid });
    this.props.firebase
      .userFire(uid)
      .get()
      .then(doc => {
        if (doc.exists) this.setState({ user: doc.data() });
        if (this.state.user.role === "vendor") {
          this.setState({ loading: false });
        }
        if (this.state.user.mainPlan) {
          this.setState({ loading: false });
        } else {
          if (this.state.user.role !== "vendor")
            this.props.history.push(ROUTES.STEPS);
        }
      })
      .catch(error => console.log(error));
  };

  render() {
    return this.state.loading ? (
      <Loader />
    ) : (
      <div>
        <Drawer user={this.state.user} />
      </div>
    );
  }
}

const Landing = compose(
  withRouter,
  withFirebase
)(LandingBase);

export default Landing;
