import React, { useState, useEffect } from "react";
import { TextField, Grid, Typography } from "@material-ui/core";
import Cards from "../Container/Card";
export default function UserAddress(props) {
  const [user, setuser] = useState(props.user);
  return (
    <Cards>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Name: {user.username}</Typography>
          <Typography variant="subtitle2">
            Main PLan: {user.mainPlanData.label}
          </Typography>
        </Grid>
      </Grid>
    </Cards>
  );
}
