import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import Cards from "../../Container/Card";
import daT from "./mainData.json";
import MainPlan from "./MainPlan";

export default function TemplatePlans(props) {
  const [dataPlan, setdataPlan] = useState(daT);
  const [labelsType, setlabelsType] = useState([]);
  const { type, data } = props;
  useEffect(() => {
    if (props.type === "main") setlabelsType(dataPlan.mainPlan);
    if (props.type === "sim") setlabelsType(dataPlan.simCards);
    if (props.type === "box") setlabelsType(dataPlan.box);
  }, []);
  const mappingBox = p => (
    <Grid item xs={3} key={p.id}>
      <Typography variant="h6" className="titleGrids" id={p.class}>
        {p.label}
      </Typography>
    </Grid>
  );

  const mappinMain = p => (
    <Grid item xs={3} key={p.id} className="gridCont">
      {p.label}
    </Grid>
  );

  const mappinSim = p => (
    <Grid item xs={12} key={p.id} className="gridCont">
      <Grid container>
        <Grid item xs={3}>
          {p.sim}
        </Grid>
        <Grid item xs={3}>
          {p.number}
        </Grid>
        <Grid item xs={3}>
          {p.mobileD}
        </Grid>
        <Grid item xs={3}>
          {p.mobileSer}
        </Grid>
      </Grid>
    </Grid>
  );
  const mappinBox = p => (
    <Grid item xs={12} key={p.id} className="gridCont">
      <Grid container>
        <Grid item xs={3}>
          {p.imei}
        </Grid>
        <Grid item xs={3}>
          {p.simcard}
        </Grid>
        <Grid item xs={3}>
          {p.plan}
        </Grid>
        <Grid item xs={3}>
          {p.status}
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <Cards cardStyle={{ padding: 0 }}>
      <Grid container justify="space-around">
        {labelsType.map(mappingBox)}
        {type === "main" ? (
          data.map(mappinMain)
        ) : type === "sim" ? (
          data.map(mappinSim)
        ) : type === "box" ? (
          data.length > 0 ? (
            data.map(mappinBox)
          ) : (
            <div style={{ padding: "1em" }}>No data</div>
          )
        ) : (
          <div>No data</div>
        )}
      </Grid>
    </Cards>
  );
}
