import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";

import Cards from "./Card";

export default function Plan(props) {
  const [planIMg, setplanIMg] = useState(props.plans.plansImg);
  const [planContent, setplanContent] = useState(props.plans.plans);
  const [planActive, setplanActive] = useState(props.plan);
  const [planRender, setplanRender] = useState({});
  const [amounSim, setAmounSim] = useState(props.amounSim);

  useEffect(() => {
    let arrayFin = [];
    let planActi = {};
    planContent.forEach(element => {
      if (element.id === planActive) {
        planActi = element;
      }
    });
    setplanRender(planActi);
    if (planActi.features) {
      planActi.features.forEach(element1 => {
        planIMg.forEach(element => {
          let ele = element;
          if (element1 === ele.id2) {
            ele.xs = planActi.xs;
            arrayFin.push(ele);
          }
        });
      });
      setplanIMg(arrayFin);
    }
  }, []);
  const mappingIcons = p => (
    <Grid
      item
      xs={p.xs}
      style={{ marginBottom: "1em", textAlign: "center" }}
      key={`${p.id} ${p.label}`}
    >
      <img
        src={p.img}
        alt={p.desc}
        width="58px"
        style={{ marginBottom: "1em", width: props.iconC }}
      />
      <Typography className={props.titleIcons4}>{p.label}</Typography>
    </Grid>
  );
  return (
    <Grid item xs={props.xs}>
      <Cards
        {...props.buttonPro}
        eventButton={() =>
          props.eventButton(planActive, amounSim, props.planLabel)
        }
        style={{ borderRadius: "2em" }}
        cardStyle={{ padding: 0 }}
      >
        <div
          style={{
            margin: "0 auto",
            textAlign: "center",
            color: "#fff",
            padding: "1em",
            paddingTop: "2em",
            paddingBottom: "3em",
            background: "linear-gradient(282deg, #6017cf 0%, #2f6fd9 100%)"
          }}
        >
          <Typography variant="h4" id="mainPlanTitle" className={props.title4}>
            {planRender.type}
          </Typography>
          <Typography variant="subtitle2">Starting at</Typography>
          <Typography variant="h5" className={props.title5}>
            ${planRender.price}
            <span>/month</span>
          </Typography>
        </div>
        <Grid
          container
          justify="space-around"
          style={{
            background: "#fff",
            borderRadius: "2em",
            padding: "2em",
            paddingBottom: "0.5em",
            marginTop: "-2em"
          }}
        >
          {planIMg.map(mappingIcons)}
        </Grid>
      </Cards>
    </Grid>
  );
}
