import React, { Component } from "react";
import { Grid, TextField } from "@material-ui/core";
import axios from "axios";

export default class Billing extends Component {
  state = {
    querieField: "",
    address: []
  };
  onChange = event => {
    const query = event.target.value;
    const self = this;
    if (!query.length > 0) {
      return this.setState({
        address: [],
        querieField: ""
      });
    }
    axios
      .get("https://autocomplete.geocoder.api.here.com/6.2/suggest.json", {
        params: {
          app_id: "fQOPYRUlUdAcgyWwaKV8",
          app_code: "nypHjx6Xa280Bn6r3DIXuA",
          query: query,
          maxresults: 1
        }
      })
      .then(function(response) {
        const address = response.data.suggestions;
        self.setState({
          address: address,
          querieField: query
        });
        self.props.changeBilling(address);
      });
  };
  render() {
    return (
      <Grid container>
        <TextField
          id="standard-querie"
          label="Address"
          name="queriefield"
          value={this.state.querieField}
          onChange={this.onChange}
          style={{ width: "95%" }}
          margin="normal"
        />
      </Grid>
    );
  }
}
