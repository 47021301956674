import React from "react";
import { Button } from "@material-ui/core";

const mappingTopCo = p => (
  <div className="contentTabGenerSub" key={p.id}>
    <div>{p.label}</div>
    <div>
      {p.val.btn1 || p.val.btn2 ? (
        <span>
          <Button>{p.val.btn1}</Button>
          <Button>{p.val.btn2}</Button>
        </span>
      ) : (
        p.val
      )}
    </div>
  </div>
);

function GenerateSubCon(props) {
  const billingArray = [
    {
      label: "Name",
      val: props.name,
      id: "billingMa0"
    },
    {
      label: "Address",
      val:
        props.billingAddress.houseNumber +
        " " +
        props.billingAddress.street +
        " " +
        props.billingAddress.postalCode,
      id: "billingMa01"
    },
    {
      label: "City",
      val: props.billingAddress.city,
      id: "billingMa0"
    },
    {
      label: "Country",
      val: props.billingAddress.country,
      id: "billingMa0"
    }
  ];

  return (
    <div id="containerSubsGene">
      <div className="dashTablesub">
        <div id="topTa">
          <h3 id="titleSubGene">Subscriptions</h3>
        </div>
        <div id="contentSubGen">{props.data.topData.map(mappingTopCo)}</div>
        <div id="midContentSub">
          <h3 id="titleSubGene2">Subscription Totals</h3>
          <div className="dashTablesub">
            <div id="topTa">
              <div className="toptabSub">Product</div>
              <div className="toptabSub"> Total</div>
            </div>
            {props.data.midData.map(mappingTopCo)}
          </div>
        </div>
        <div id="billinAddr">
          <h3 id="titleSubGene2">Billing address</h3>
          <div style={{ marginLeft: "2em" }}>
            {billingArray.map(mappingTopCo)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateSubCon;
