import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";

import plans from "./dataSteps.json";
import Plan from "../../Container/Plan";
export default function Step1(props) {
  //funtions
  const selecPlan = (plan, size, planLabel) => {
    props.changeStep(plan, true, size, planLabel);
  };
  let buttonPros = {
    buttonStyle: {
      width: "100%",
      padding: "1em",
      marginTop: 0,
      background: "linear-gradient(86deg, #ffc107 0%, #ffa000 100%)",
      color: "#000"
    },
    buttonText: "Select",
    actions: true
  };
  //mappins
  return (
    <Grid item xs={10}>
      <Typography
        variant="h4"
        style={{ textAlign: "center", margin: "1em", marginTop: 0 }}
      >
        Select Your Plan
      </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={6}
      >
        <Plan
          buttonPro={buttonPros}
          eventButton={selecPlan}
          plans={plans}
          amounSim={1}
          plan="twoplay"
          planLabel="MOBILE TWO PLAY"
          xs={4}
        />
        <Plan
          buttonPro={buttonPros}
          eventButton={selecPlan}
          plans={plans}
          amounSim={2}
          plan="tripleplay"
          planLabel="MOBILE TRIPLE PLAY"
          xs={4}
        />
        <Plan
          buttonPro={buttonPros}
          eventButton={selecPlan}
          amounSim={3}
          plans={plans}
          plan="quadrupleplay"
          planLabel="MOBILE QUADRUPLE PLAY"
          xs={4}
        />
      </Grid>
    </Grid>
  );
}
