import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import data from "./billinData.json";
export default function SimCard(props) {
  const [simCard, setsimCard] = useState(data.simCard.twoplay);

  useEffect(() => {
    const { twoplay, tripleplay, quadrupleplay } = data.simCard;
    const { plan } = props;
    if (plan === "twoplay") {
      setsimCard(twoplay);
    } else if (plan === "tripleplay") {
      setsimCard(tripleplay);
    } else if (plan === "quadrupleplay") {
      setsimCard(quadrupleplay);
    }
  }, []);

  const onChange = event => {
    let sims = simCard;
    let temArray = [];
    let arrayToDB = [];
    sims.forEach(element => {
      if (element.name === event.target.name)
        element.value = event.target.value;
      temArray.push(element);
      arrayToDB.push({
        name: element.name,
        label: element.label,
        sim: element.value,
        number: "Processing",
        mobileD: element.mobileD,
        mobileSer: element.mobileSer
      });
    });
    setsimCard(temArray);
    props.sim(arrayToDB);
  };

  const mappinSim = p => (
    <TextField
      id={p.id}
      label={p.label}
      name={p.name}
      value={p.value}
      onChange={onChange}
      style={{ width: "100%" }}
      key={`${p.id}`}
      margin="normal"
    />
  );
  return <div>{simCard.map(mappinSim)}</div>;
}
