import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import App from "./Components/App/index.jsx";
import store from "./store";

import Firebase, { FirebaseContext } from "./Components/Firebase/index.js";
ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App store={store} />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
