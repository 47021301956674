import React, { Component } from "react";
import { Card, CardContent, CardActions, Button } from "@material-ui/core";
export default class Cards extends Component {
  render() {
    const Actions = props => (
      <CardActions style={{ padding: 0 }}>
        <Button
          style={props.buttonStyle}
          color="primary"
          variant="contained"
          disabled={props.disabled}
          onClick={this.props.eventButton}
        >
          {props.buttonText}
        </Button>
      </CardActions>
    );
    return (
      <Card style={this.props.style} onClick={this.props.onClick}>
        <CardContent style={this.props.cardStyle}>
          {this.props.children}
        </CardContent>
        {this.props.actions ? (
          <Actions
            buttonStyle={this.props.buttonStyle}
            eventButton={this.props.eventButton}
            buttonText={this.props.buttonText}
            disabled={this.props.disabled}
          />
        ) : (
          <div></div>
        )}
      </Card>
    );
  }
}
