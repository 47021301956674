import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Cards from "../Container/Card";
import UserInfo from "../LandingV2/UserInfo/UserInfo";
export default function RenderInfo(props) {
  const [user, setusers] = useState(props.user.data);
  const [id, setid] = useState(props.user.id);
  return (
    <Grid item xs={3}>
      <UserInfo eventButton={props.eventButton} label="Show" userData={user} />
    </Grid>
  );
}
