import React, { Component } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { Elements } from "react-stripe-elements";

import Cards from "../../Container/Card";
import Billing from "./BillingInfo/Billing";
import Checkout from "../../Stripe/Checkout";
import PlanTemplate from "../../Container/Plan";
import Sim from "./BillingInfo/SimCard";
import Loader from "../../Container/Loader";
import VendorId from "../Steps/BillingInfo/VendorId";

//data
import plans from "./dataSteps.json";
export default class Step3 extends Component {
  async submit(ev) {
    // User clicked submit
    let { token } = await this.props.stripe.createToken({});
    let response = await fetch("/api/create-custume", {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      body: token.id
    });
    if (response.ok) console.log("Purchase Complete!");
  }
  render() {
    const props = this.props;
    const mappingAddress = ({ address }) => (
      <Grid item xs={12} key={`${address.houseNumber}${address.postalCode}`}>
        <div>
          Street: {address.houseNumber} {address.street}
        </div>
        <div>City: {address.city}</div>
        <div>State: {address.state}</div>
        <div>Postal Code: {address.postalCode}</div>
      </Grid>
    );
    return props.show ? (
      <Grid item xs={10}>
        <Typography
          variant="h4"
          style={{ textAlign: "center", margin: "1em", marginTop: 0 }}
        >
          Billing Information
        </Typography>
        <Grid container spacing={8} justify="space-around">
          <Grid item xs={12} sm={7} className="billinStyle">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Billing
                  changeBilling={props.changeBilling}
                  billingInformation={props.billingInformation}
                />
                <Sim sim={props.sim} plan={props.plan} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="address">
                  <Typography
                    component="div"
                    variant="subtitle2"
                    className="address"
                  >
                    Your Address
                    {props.billingInformation.map(mappingAddress)}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <VendorId changeVendor={props.changeVendor} />
                <Elements>
                  <Checkout
                    invalid={props.invalid}
                    plan={props.plan}
                    show={props.show}
                    userData={props.userData}
                    stripeIdC={props.stripe}
                  />
                </Elements>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5}>
            <PlanTemplate plan={props.plan} plans={plans} xs={10} />
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <Loader />
    );
  }
}
