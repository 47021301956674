import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
    textAlign: "center",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    },
    marginTop: "2em"
  }
}));

export default function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/mobilepcstv-cf2c1.appspot.com/o/blackcopy.png?alt=media&token=ca25a9da-3609-4086-a3ab-becbe256fb53"
          alt="Logo"
          width="50%"
        />
      </div>
      <CircularProgress size={200} thickness={2} color="secondary" />
    </div>
  );
}
