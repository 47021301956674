import { observable, computed, action, decorate, autorun } from "mobx";
class storeC {
  user = {
    name: "",
    email: "",
    plan: "",
    billingAddress: {}
  };
  mobilephone = [
    {
      text: "Unlimited"
    },
    {
      text: "Talk & Text"
    },
    {
      text: "Unlimited High-Speed 4G LTE Data"
    },
    {
      text: "+ Mobile HotSpot at 3G speeds"
    },
    {
      text: "Video Streaming at 480p"
    }
  ];
  mobileInternet = [
    {
      text: "Truly Unlimited Data Mobile "
    },
    {
      text: "Hotspot Plans For Home, and Business"
    },
    {
      text: "Connect Multipe Devices"
    },
    {
      text: "No Contracts & No Data Caps"
    }
  ];
  plans = {
    twoPlay: {
      price: 79,
      service: "Mobile Internet + Live TV",
      features: {}
    },
    triple: {
      price: 119,
      service: "Mobile Internet + Live TV + Mobile Service",
      features: {}
    }
  };
}
decorate(storeC, {
  user: observable,
  plans: observable
});

let store = (window.store = new storeC());
export default store;
