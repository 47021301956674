import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";

import LandingPage from "../LandingV3";
import SignUpPage from "../SignUp";
import SignInPage from "../SignIn";
import PasswordForgetPage from "../PasswordForget";
import HomePage from "../Home";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import Steps from "../SignUp/Steps";
import Vendor from "../Vendor/Vendor";
import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import "../../css/style.css";

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: "#ffcd38",
      main: "#ffc107",
      dark: "#b28704"
    },
    primary: {
      light: "#7f45d8",
      main: "#6017cf",
      dark: "#431090"
    }
  }
});

theme.typography.h4 = {
  [theme.breakpoints.up("xs")]: {
    fontSize: "1.3rem",
    fontWeight: 500
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "3rem",
    fontWeight: 600
  }
};

theme.typography.h6 = {
  fontWeight: 450,
  fontSize: "1.1rem"
};
class App extends React.Component {
  render() {
    return (
      <StripeProvider apiKey="pk_test_FjnG5uR7EshYB8HPyCrMv0Lw">
        <ThemeProvider theme={theme}>
          <Router>
            <div>
              {/* <Navigation />
      <hr /> */}
              <Route exact path={ROUTES.LANDING} component={LandingPage} />
              <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route
                path={ROUTES.PASSWORD_FORGET}
                component={PasswordForgetPage}
              />
              <Route path={ROUTES.HOME} component={HomePage} />
              <Route path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route path={ROUTES.ADMIN} component={AdminPage} />
              <Route path={ROUTES.STEPS} component={Steps} />
              <Route path={ROUTES.VENDOR} component={Vendor} />
            </div>
          </Router>
        </ThemeProvider>
      </StripeProvider>
    );
  }
}
export default withAuthentication(App);
