import React, { Component } from "react";
import { Button, Grid } from "@material-ui/core";
import { CardElement, injectStripe } from "react-stripe-elements";
class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };
    this.submit = this.submit.bind(this);
  }
  async submit(ev) {
    const self = this;
    const { email, username } = this.props.userData;
    const { plan } = this.props;
    // User clicked submit
    let { token } = await this.props.stripe.createToken({});
    if (token) {
      await fetch(
        "https://dash.mobilepcstv.com/.netlify/functions/create-customers",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            token: token.id,
            email: email,
            name: username,
            description: `User with plan ${plan}`,
            plan: plan
          })
        }
      )
        .then(res => {
          return res.text();
        })
        .then(custu => {
          let data = JSON.parse(custu);
          console.log(data);
          if (data.customerId) {
            self.props.stripeIdC(data.customerId);
          } else {
            this.submit();
          }
        });
    }
  }

  render() {
    return (
      <div className="checkout" style={{ marginTop: "2em" }}>
        <CardElement />
        <br />
        <Button
          variant="contained"
          color="secondary"
          onClick={this.submit}
          disabled={this.props.invalid}
          style={{ marginTop: "1m", width: "100%" }}
        >
          Purchase
        </Button>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
