import React, { Component } from "react";
import { Grid, TextField } from "@material-ui/core";
import axios from "axios";

export default class Billing extends Component {
  state = {
    vendorId: ""
  };
  onChange = event => {
    this.setState({ vendorId: event.target.value });
    this.props.changeVendor(this.state.vendorId);
  };
  render() {
    return (
      <Grid container>
        <TextField
          id="vendor-id"
          label="Vendor ID"
          name="vendorid"
          value={this.state.vendorId}
          onChange={this.onChange}
          style={{ width: "95%" }}
          margin="normal"
        />
      </Grid>
    );
  }
}
