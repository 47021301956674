import React, { useState } from "react";
import { Grid, Avatar, TextField, Button } from "@material-ui/core";

//icons
import { AiOutlineDashboard, AiFillSetting } from "react-icons/ai";
import { FaStar, FaMoneyBillAlt, FaUserAstronaut } from "react-icons/fa";
import {
  IoMdLogOut,
  IoMdAddCircleOutline,
  IoIosAddCircle
} from "react-icons/io";

//moment
import Moment from "react-moment";
import "moment-timezone";
//custume components
import Plan from "../Plan";
import GenerateBill from "./GenerateBill";
import GenerateSubCon from "./GenerateSubCon";
//data to render
import { client } from "./data.json";
import { withFirebase } from "../../Firebase";
import plans from "../../SignUp/Steps/dataSteps";

function Drawer(props) {
  const { user } = props;
  const logo =
    "https://firebasestorage.googleapis.com/v0/b/mobilepcstv-cf2c1.appspot.com/o/blackcopy.png?alt=media&token=ca25a9da-3609-4086-a3ab-becbe256fb53";
  //state
  const [dashs, setdash] = useState({
    dash: "dash",
    title: "Dashboard",
    contentWB:
      "From your account dashboard you can view your recent orders, manage your shipping and billing addresses, and edit your password and account details."
  });
  const [subC, setsubC] = useState(client.subConTemp);
  const [billC, billsetC] = useState(client.billConTemp);
  const [manageC, setmanageC] = useState(client.manageTab);
  const [simAr, setsimAr] = useState(user.mainPlanData.simCard);
  const [count, setcount] = useState(0);
  const [find, setfind] = useState(true);
  const [show, setshow] = useState({
    bill: false,
    sub: false,
    mid: true
  });
  const [infoToGenerate, setinfoToGenerate] = useState({
    invoice: "nmlb51251",
    date: "2 Feb 2020",
    name: "Pedro",
    descrip: "Two play plan",
    amount: "$79.00",
    type: "bill"
  });

  const [infoToGeneSub, setinfoToGeneSub] = useState(
    client.subConTemp[0].data[3].renderInfo
  );

  //funtions

  const changeDash = (val, title, content) => {
    setdash({ dash: val, title: title, contentWB: content });
    setshow({ bill: false, mid: true, sub: false });
    if (val === "sup") checkSub();
    if (val === "bills") checkSub();
    if (val === "manage") changeManageFields();
  };

  const handleSub = data => {
    let arrayTemp = [];
    let arrayBill = [];
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    data.forEach(element => {
      let date = new Date(element.current_period_end * 1000);
      let dateStar = new Date(element.current_period_start * 1000);
      let time =
        months[date.getMonth()] +
        " " +
        date.getDate() +
        " " +
        date.getFullYear();

      let timeStar =
        months[dateStar.getMonth()] +
        " " +
        dateStar.getDate() +
        " " +
        dateStar.getFullYear();

      let elementTo = {
        id: element.last_invoice,
        data: [
          {
            label: element.status,
            id: `subC0${element.latest_invoice}`
          },
          {
            label: time,
            id: `subC01${element.latest_invoice}`
          },
          {
            label: `$${element.plan.amount / 100}.00`,
            id: `subC02${element.latest_invoice}`
          },
          {
            label: "View",
            id: `subC03${element.latest_invoice}`,
            renderInfo: {
              type: "sub",
              topData: [
                {
                  label: "Status",
                  val: element.status,
                  id: `subGene0${element.latest_invoice}`
                },
                {
                  label: "Start Date",
                  val: timeStar,
                  id: `subGene01${element.latest_invoice}`
                },
                {
                  label: "Last Order Date",
                  val: timeStar,
                  id: `subGene02${element.latest_invoice}`
                },
                {
                  label: "Next Payment Date",
                  val: time,
                  id: `subGene03${element.latest_invoice}`
                },
                {
                  label: "Actions",
                  val: {
                    btn1: "suspend",
                    btn2: "cancel"
                  },
                  id: `subGene04${element.latest_invoice}`
                }
              ],
              midData: [
                {
                  label: element.plan.id,
                  val: `$${element.plan.amount / 100}.00/month`,
                  id: `midSubge0${element.latest_invoice}`
                },
                {
                  label: "Subtotal:",
                  val: `$${element.plan.amount / 100}.00`,
                  id: `midSubge01${element.latest_invoice}`
                },
                {
                  label: "Tax",
                  val: "0",
                  id: `midSubge02${element.latest_invoice}`
                },
                {
                  label: "Payment method:",
                  val: "Credit Card (Stripe)",
                  id: `midSubge03${element.latest_invoice}`
                },
                {
                  label: "Total:",
                  val: `$${element.plan.amount / 100}.00`,
                  id: `midSubge01${element.latest_invoice}`
                }
              ]
            }
          }
        ]
      };

      let elementToBill = {
        id: element.last_invoice,
        data: [
          {
            label: element.plan.id,
            id: `billC0${element.latest_invoice}`
          },
          {
            label: timeStar,
            id: `billC01${element.latest_invoice}`
          },
          {
            label: `$${element.plan.amount / 100}.00`,
            id: `billC02${element.latest_invoice}`
          },
          {
            label: "View",
            id: `billC03${element.latest_invoice}`,
            renderInfo: {
              invoice: element.latest_invoice,
              date: timeStar,
              name: user.username,
              descrip: element.plan.id,
              amount: `$${element.plan.amount / 100}.00`,
              type: "bill"
            }
          }
        ]
      };
      arrayTemp.push(elementTo);
      arrayBill.push(elementToBill);
    });
    setsubC(arrayTemp);
    billsetC(arrayBill);
    setcount(0);
    setfind(false);
  };

  const checkSub = async () => {
    if (find) {
      await fetch(
        "https://dash.mobilepcstv.com/.netlify/functions/retrieve-customer",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            stripeid: user.stripeId
          })
        }
      )
        .then(res => {
          return res.text();
        })
        .then(custu => {
          let data = JSON.parse(custu);
          if (data.subcrition.length > 0) {
            handleSub(data.subcrition);
          } else {
            setcount(prevCount => prevCount + 1);
            if (count < 2) {
              checkSub();
            }
          }
        })
        .catch(err => {
          console.log(err);
          setsubC(client.subConTemp);
          billsetC(client.billConTemp);
        });
    }
  };
  const changeManageFields = data => {
    let arrayTemp = [];
    arrayTemp = [
      {
        label: "Name",
        value: user.username,
        id: "manage0"
      },
      {
        label: "Email Address",
        value: user.email,
        id: "manage01"
      },
      {
        label: "City",
        value: user.billingAddress.city,
        id: "manage02"
      },
      {
        label: "State",
        value: user.billingAddress.county,
        id: "manage03"
      },
      {
        label: "Post Code",
        value: user.billingAddress.postalCode,
        id: "manage04"
      },
      {
        label: "Country",
        value: user.billingAddress.country,
        id: "manage05"
      }
    ];
    setmanageC(arrayTemp);
  };

  const changeInfoToGene = data => {
    if (data.type === "bill") {
      setinfoToGenerate(data);
      setshow({ sub: false, mid: false, bill: true });
    }
    if (data.type === "sub") {
      setinfoToGeneSub(data);
      setshow({ sub: true, mid: false, bill: false });
    }
  };

  //components
  //menus ,common
  const mappinMenu = p => (
    <MenuItem label={p.label} key={p.id} das={p.dash} contentWB={p.contentWB}>
      {p.icon === "dashboard" ? (
        <AiOutlineDashboard size="24px" />
      ) : p.icon === "supscriptions" ? (
        <FaStar size="24px" />
      ) : p.icon === "bill" ? (
        <FaMoneyBillAlt size="24px" />
      ) : p.icon === "manage" ? (
        <AiFillSetting size="24px" />
      ) : (
        <span />
      )}
    </MenuItem>
  );
  const mappingDashTab = p => <div key={p.id}>{p.label}</div>;

  const mappingSims = p => (
    <div key={p.sim} id="contentTab">
      <div>{p.sim}</div>
      <div>{p.number}</div>
    </div>
  );

  const mappingSubC = p => (
    <div id="contentTab" key={p.id}>
      {p.data.map(p2 => (
        <div key={p2.id}>
          {p2.label === "View" ? (
            <Button onClick={() => changeInfoToGene(p2.renderInfo)}>
              {p2.label}
            </Button>
          ) : (
            p2.label
          )}
        </div>
      ))}
    </div>
  );

  const mappingManage = p => (
    <TextField id="standard-basic" key={p.id} value={p.value} label={p.label} />
  );

  const MenuItem = p => (
    <div
      className="menuDrawer"
      onClick={() => changeDash(p.das, p.label, p.contentWB)}
    >
      <div id="iconDr">{p.children}</div>
      <div id="textDr">{p.label}</div>
    </div>
  );

  //middle
  const MiddleDashCl = p => (
    <div>
      {" "}
      <div className="ConDashCl">
        <div id="plan">
          <Plan
            plans={plans}
            plan={user.mainPlan}
            title4="title4"
            title5="title5"
            titleIcons4="titleicons4"
            iconC="40px!important"
          />
        </div>
        <div id="dashTable">
          <div id="topTa">{client.dashTab.map(mappingDashTab)}</div>
          <div>{simAr.map(mappingSims)}</div>
        </div>
      </div>
    </div>
  );

  const MiddleSups = p => (
    <div className="midSub">
      <div id="topTa">{client.subTab.map(mappingDashTab)}</div>
      {subC.map(mappingSubC)}
    </div>
  );

  const MiddleBills = p => (
    <div className="midSub">
      <div id="topTa">{client.billTab.map(mappingDashTab)}</div>
      {billC.map(mappingSubC)}
    </div>
  );

  const MiddleManage = p => (
    <div className="midSub2">
      <div id="contentTab2">{manageC.map(mappingManage)}</div>
    </div>
  );

  const Middle = () => (
    <div>
      <div className="containerMiDr">
        <h3>{dashs.title}</h3>
        <div>
          <Moment format="MMM Do YY, dddd" />
        </div>
      </div>
      <div className="containerWB">
        <div id="pwp">
          <h3>Welcome Back {user.username}!!</h3>
          {dashs.contentWB}
        </div>
        <div id="imgwp">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/mobilepcstv-cf2c1.appspot.com/o/hombre%20(1).png?alt=media&token=997cc9f8-7059-4f75-8494-b70299cf0b18"
            alt="avatatwb"
            width="114px"
            height="114px"
          />
        </div>
      </div>
      {dashs.dash === "dash" ? (
        <MiddleDashCl />
      ) : dashs.dash === "sup" ? (
        <MiddleSups />
      ) : dashs.dash === "bills" ? (
        <MiddleBills />
      ) : dashs.dash === "manage" ? (
        <MiddleManage />
      ) : (
        <span />
      )}
    </div>
  );

  const GridCol = p => (
    <Grid
      container
      item
      xs={p.xs}
      className={p.classN}
      direction="column"
      justify="space-between"
      id={p.id}
      alignItems="center"
    >
      {p.children}
    </Grid>
  );

  return (
    <div>
      <Grid container className="gridDrawer">
        <GridCol xs={2} classN="sidesDrawes" id="sidedRA">
          <Grid item id="logo">
            <img src={logo} alt="Logo" width="100%" />
          </Grid>
          <Grid item id="menuDra">
            {client.clientMenu.map(mappinMenu)}
          </Grid>
          <Grid item />
          <Grid item />
          <Grid item id="menuDra">
            <MenuItem label="Contact Us" />
          </Grid>
        </GridCol>
        <Grid item xs={7} className="middleDrawes">
          {show.mid ? (
            <Middle />
          ) : show.bill ? (
            <GenerateBill data={infoToGenerate} logo={logo} />
          ) : show.sub ? (
            <GenerateSubCon
              data={infoToGeneSub}
              billingAddress={user.billingAddress}
              name={user.username}
              logo={logo}
            />
          ) : (
            <span />
          )}
        </Grid>
        <GridCol xs={3} classN="sidesDrawes" id="sidedRA2">
          <Grid item id="menuDra">
            <div
              className="menuDrawer"
              onClick={() => props.firebase.doSignOut()}
              style={{ justifyContent: "space-between" }}
            >
              <div id="iconDr">Logout</div>
              <div id="textDr">
                <IoMdLogOut size="24px" />
              </div>
            </div>
          </Grid>
          <Grid item>
            <div id="avatarDiv">
              <Avatar id="avatarImg">
                <FaUserAstronaut size="100px" />
              </Avatar>
              <div id="drawerAvatarText">
                <div>{user.username}</div>
                <div>{user.mainPlanData.label}</div>
              </div>
            </div>
          </Grid>
          <Grid item />
          <Grid item />
          <Grid item id="menuDra">
            <MenuItem id="menuDra" label="AddOns">
              <IoMdAddCircleOutline size="24px" />
            </MenuItem>
            <MenuItem id="menuDra" label="Add Plan">
              <IoIosAddCircle size="24px" />
            </MenuItem>
          </Grid>
        </GridCol>
      </Grid>
    </div>
  );
}
export default withFirebase(Drawer);
